import { useState, useEffect } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useDisplayBet, collect, closeDisplayBet } from "@rngstudio/flip";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogBody,
  Button,
  Center,
} from "@chakra-ui/react";

const Collect = ({ enabled, pubkey, collect }) => {
  if (enabled) {
    return (
      <Button colorScheme="yellow" onClick={() => collect(pubkey)}>
        Collect
      </Button>
    );
  }

  return <Button disabled>Collect</Button>;
};

const BetResult = () => {
  const wallet = useWallet();
  const bet = useDisplayBet();
  const [messageTimerDone, setMessageTimerDone] = useState(false);

  useEffect(() => {
    if (!!bet) {
      setMessageTimerDone(false);
      setTimeout(() => setMessageTimerDone(true), 5_000);
    }
  }, [bet]);

  if (!wallet || !wallet.publicKey) {
    return <></>;
  }
  if (!bet) {
    return <></>;
  }

  const isWinner = wallet?.publicKey.toString() === bet.winner.toString();
  const collectEnabled = bet.collected === 0;

  return (
    <AlertDialog isOpen={true} onClose={closeDisplayBet}>
      <AlertDialogOverlay>
        <AlertDialogContent className="popup">
          {isWinner && (
            <div>
              <Center>
                <video
                  className="video"
                  autoPlay={true}
                  playsInline={true}
                  muted
                >
                  <source src={"/win.webm"} type="video/webm" />
                  <source src={"/win.mp4"} type="video/mp4" />
                </video>
              </Center>
              {messageTimerDone && (
                <div>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    You Won!
                  </AlertDialogHeader>
                  <AlertDialogBody>Congratulations, you won!</AlertDialogBody>
                  <AlertDialogFooter>
                    <Collect
                      enabled={collectEnabled}
                      pubkey={bet.pubkey}
                      close={closeDisplayBet}
                      collect={() => collect(bet.pubkey)}
                    />
                    <Button onClick={closeDisplayBet} ml={3}>
                      Close
                    </Button>
                  </AlertDialogFooter>
                </div>
              )}
            </div>
          )}
          {!isWinner && (
            <div>
              <Center>
                <video
                  className="video"
                  autoPlay={true}
                  playsInline={true}
                  muted
                >
                  <source src={"/lose.webm"} type="video/webm" />
                  <source src={"/lose.mp4"} type="video/mp4" />
                </video>
              </Center>
              {messageTimerDone && (
                <div>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    You Lost!
                  </AlertDialogHeader>
                  <AlertDialogBody>Better Luck Next Time!</AlertDialogBody>
                  <AlertDialogFooter>
                    <Button onClick={closeDisplayBet} ml={3}>
                      Close
                    </Button>
                  </AlertDialogFooter>
                </div>
              )}
            </div>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default BetResult;
