import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { GiSpades } from "react-icons/gi";

const Navigation = () => {
  return (
    <>
      <Breadcrumb separator={<Icon as={GiSpades} color='gray.500' />}>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink as={Link} to="/">
            Bet
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/results">
            My Results
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </>
  );
};

export default Navigation;
