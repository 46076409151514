import { useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import "./App.css";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SlopeWalletAdapter,
  SolflareWalletAdapter,
  SolletExtensionWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { FlipProvider, FlipInitializer } from "@rngstudio/flip";
import { clusterApiUrl } from "@solana/web3.js";
import { BrowserRouter } from "react-router-dom";
import Bet from "./Bet";
import Results from "./Results";
import Navigation from "./Navigation";
import Loading from "./Loading";
import BetResult from "./BetResult";
import PendingBets from "./PendingBets";
import Error from './Error';
import Disclaimer from "./Disclaimer";
import { Routes, Route, Link } from "react-router-dom";
import { ChakraProvider, Container, Stack, Image } from "@chakra-ui/react";
import theme from "./theme";

require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
  const network = 
  process.env.REACT_APP_NETWORK === "mainnet" ? WalletAdapterNetwork.Mainnet : 
  process.env.REACT_APP_NETWORK === "testnet" ? WalletAdapterNetwork.Testnet :
  WalletAdapterNetwork.Devnet

  console.log('network is', network);

  //todo: network as env var
  const endpoint = useMemo(() => process.env.REACT_APP_NETWORK === "mainnet" ? process.env.NEXT_PUBLIC_CLUSTER_URL || "https://wild-wispy-meadow.solana-mainnet.quiknode.pro" : clusterApiUrl(network), [network]);
  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SlopeWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
      new SolletExtensionWalletAdapter({ network }),
    ],
    [network]
  );

  return (
    <div className="App">
      <ChakraProvider theme={theme}>
        <Container>
          <Stack>
            <BrowserRouter>
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <FlipProvider partnerId="2khLz1yTcVWfJCh6bB9Bz2sk1hBZ2fvp7aqX1R9FjgW4" network={network}>
                    <FlipInitializer />
                    <WalletModalProvider>
                      <Disclaimer />
                      <BetResult />
                      <Loading />
                      <Error />
                      <Link to="/"><Image
                        className="logo"
                        src="/logo.png"
                        alt="The 77 Casino"
                      /></Link>
                      <Routes>
                        <Route path="/" element={<Bet />} />
                        <Route path="/results" element={<Results />} />
                      </Routes>
                      <PendingBets />
                      <Navigation />
                    </WalletModalProvider>
                  </FlipProvider>
                </WalletProvider>
              </ConnectionProvider>
            </BrowserRouter>
          </Stack>
        </Container>
      </ChakraProvider>
    </div>
  );
}

export default App;
