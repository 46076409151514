import { useState } from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { useRadioGroup, HStack, Center, Image } from "@chakra-ui/react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import BN from "bn.js";
import { usePendingBets, createBet } from "@rngstudio/flip";
import BetRadio from "./BetRadio";
import {
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

const BetBox = () => {
  const pendingBets = usePendingBets();
  const [betAmount, setBetAmount] = useState(25);
  const wallet = useWallet();
  const [loading, setLoading] = useState(false);
  // const toast = useToast();

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "amount",
    defaultValue: "25",
    onChange: (amount) => setBetAmount(Number(amount)),
  });
  const group = getRootProps();

  if (!wallet || !wallet.publicKey) {
    return (
      <Center>
        <WalletMultiButton />
      </Center>
    );
  }

  const options = ["5", "10", "25", "50", "100"];

  const amount = new BN(LAMPORTS_PER_SOL).divn(100).muln(Number(betAmount));
  const instants = pendingBets.reduce(
    (total, bet) => {
      for (const i of [5, 10, 25, 50, 100]) {
        if (
          bet.amount.eq(new BN(LAMPORTS_PER_SOL).divn(100).muln(Number(i))) &&
          bet.better.toString() !== wallet.publicKey.toString()
        ) {
          return { ...total, [i]: true };
        }
      }
      return total;
    },
    { 5: false, 10: false, 25: false, 50: false, 100: false }
  );

  return (
    <div>
      <div>
        <HStack {...group}>
          {options.map((value) => {
            const radio = getRadioProps({ value });
            const isInstant = instants[Number(value)];
            return (
              <BetRadio key={value} isInstant={isInstant} {...radio }>
                {value}
              </BetRadio>
            );
          })}
        </HStack>

        <button
          type="button"
          className="flipButton"
          onClick={async () => {
            setLoading(true);
            await createBet(amount);
            setLoading(false);
            // toast({
            //   title: 'Bet placed.',
            //   description: "We're matching you up with another player now",
            //   status: 'success',
            //   duration: 3000,
            //   isClosable: true,
            // })
          }}
          disabled={loading}
        >
          Flip
        </button>

        <Image className="dice" src="/dice.png" alt="Dice"/>
      </div>
    </div>
  );
};

export default BetBox;
