import { useToast } from "@chakra-ui/react";
import { usePendingBets } from "@rngstudio/flip";
import { useEffect, useState } from "react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";

const PendingBets = () => {
    const toast = useToast();
    const pendingBets = usePendingBets();
    const [betsToasted, setBetsToasted] = useState([]);
    const wallet = useWallet();

    useEffect(() => {
        if (!wallet || !wallet.publicKey) {
            return;
        }
        for (const bet of pendingBets) {
            const key = bet.pubkey.toString()
            if (bet.better.toString() !== wallet.publicKey.toString()) {
                continue;
            }
            if (!betsToasted.includes(key)) {
                setBetsToasted([...betsToasted, key]);
                toast({
                    title: `Waiting for players for your bet of ${bet.amount / LAMPORTS_PER_SOL}`,
                    isClosable: false,
                    position: 'bottom-right',
                    duration: null,
                    id: key,
                })
            }
        }
    }, [pendingBets, wallet, toast, betsToasted]);

    return <></>
}

export default PendingBets;