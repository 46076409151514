import { useToast } from "@chakra-ui/react";
import { useError, closeError } from "@rngstudio/flip";
import { useEffect } from "react";

const errors = {
  insufficientFunds: "You don't have enough funds to place this bet",
  userCancel:
    "We couldn't place this bet because you cancelled the transaction",
  alreadyWithdrawn: "You have already collected this bet",
};

const PendingBets = () => {
  const toast = useToast();
  const error = useError();

  useEffect(() => {
    if (error) {
      toast({
        position: "bottom-left",
        status: "error",
        description: errors[error],
        onCloseComplete: closeError,
        duration: 9_000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  return <></>;
};

export default PendingBets;
