import { useLoading } from "@rngstudio/flip";
import { Spinner } from "@chakra-ui/react";
require("./Loading.css");

const Loading = () => {
  const loading = useLoading();

  if (!loading) {
    return <div></div>;
  }

  return (
    <div className="loading">
      <Spinner color="red.500" />
    </div>
  );
};

export default Loading;
