import { useWallet } from "@solana/wallet-adapter-react";
import { fromUnixTime, formatRelative } from "date-fns";
import { useBets, collect } from "@rngstudio/flip";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import {
    WalletMultiButton,
  } from "@solana/wallet-adapter-react-ui";
  

import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Tooltip,
    Button,
    Center,
    Heading
  } from '@chakra-ui/react'

const Result = ({ bet }) => {
  const wallet = useWallet();
  const isWinner = wallet?.publicKey.toString() === bet.winner.toString();
  const collectEnabled = bet.collected === 0 && isWinner;
  const opponent = isWinner ? bet.loser.toString() : bet.winner.toString();
  return (
    <Tr>
      <Td>{isWinner ? "Yes" : "No"}</Td>
      <Td className="trim no-mobile">
        <Tooltip label={opponent}>
            {opponent}
        </Tooltip>
      </Td>
      <Td>{bet.amount / LAMPORTS_PER_SOL}</Td>
      <Td className="no-mobile">
        {formatRelative(fromUnixTime(bet.updated_at), new Date())}
      </Td>
      <Td>
        {collectEnabled ? (
          <Button
            colorScheme="yellow"
            onClick={() => collect(bet.pubkey)}
          >
            Get
          </Button>
        ) : (
          <Button disabled>
            Get
          </Button>
        )}
      </Td>
    </Tr>
  );
};

const Results = () => {
  const wallet = useWallet();
  const bets = useBets();


  if (!wallet || !wallet.publicKey) {
    return (
      <Center>
        <WalletMultiButton />
      </Center>
    );
  }


  return (
    <div>
      <Heading className="niceFont">Your Results</Heading>
      <br />
      <div className="results">
        <Table className="nes-table is-dark is-wide">
          <Thead>
            <Tr>
              <Th>Won?</Th>
              <Th className="no-mobile">Against</Th>
              <Th>Amount</Th>
              <Th className="no-mobile">When</Th>
              <Th>Collect</Th>
            </Tr>
          </Thead>
          <Tbody>
          {bets
            .filter((bet) =>
              [bet.winner.toString(), bet.loser.toString()].includes(
                wallet?.publicKey.toString()
              )
            )
            .map((bet) => (
              <Result key={bet.pubkey} bet={bet} collect={collect} />
            ))}
        </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default Results;