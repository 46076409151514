import { useRadio, Box, Badge } from "@chakra-ui/react";

const BetRadio = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();
  const { isInstant } = props;

  return (
    <Box as="label">
      <Badge className="instant" colorScheme="purple">
        {isInstant && 'Instant'}
      </Badge>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        _checked={{
          bg: "yellow.500",
          color: "white",
          borderColor: "yellow.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {props.children / 100} SOL
      </Box>
    </Box>
  );
};

export default BetRadio;
